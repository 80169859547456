import React from 'react'
import Navigation from "../../components/CustomComponents/Nav/Nav.js"; 
import Contact from "../../components/Contact/Contact.js"

function contact() {
  return (
    <div>
      <Navigation/>
      <Contact/>
    </div>
   
  )
}

export default contact;